import React, { useEffect, useState, useContext } from "react"
import {
  Box,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Badge,
} from "@mui/material"
import { withStyles, makeStyles } from "@mui/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  fetchInactiveChats,
  resetActiveChat,
  setActiveChat,
} from "redux/actions"
import { anonymousModeContext } from "contexts"
import { useDispatch } from "react-redux"
import ChatCard from "./ChatCard"

const Accordion = withStyles((theme) => ({
  root: {
    border: 0,
    boxShadow: "none",
    position: "relative",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      flex: 1,
      margin: 0,
      borderBottom: `1px solid ${theme.palette.gray}`,
    },
    "& .MuiAccordionSummary-root .MuiAccordionSummary-content": {
      overflow: "initial",
      margin: `${theme.spacing(1.75, 0)}`,
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.lighter_gray,
    borderBottom: `1px solid ${theme.palette.gray}`,
    "&$expanded": {
      minHeight: 53,
    },
  },
  content: {
    overflow: "hidden",
    "& .MuiTypography-root": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 18,
    },
    "&$expanded": {
      margin: "12px 0",
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.lighter_gray,
    borderLeft: `0.5px solid ${theme.palette.gray}`,
    borderRight: `0.5px solid ${theme.palette.gray}`,
  },
  ChatContainer: {
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    top: 56,
    bottom: 0,
    left: 0,
    right: 0,
    "&::-webkit-scrollbar": {
      height: 1,
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: 4,
    },
  },
  ToggleContainer: {
    marginLeft: "10px",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  badge: {
    "& .MuiBadge-badge": {
      height: "17px",
      minWidth: "17px",
      width: "17px",
      color: theme.palette.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
      fontSize: "0.6rem",
      right: theme.spacing(-1),
    },
  },
}))

const SideBar = ({
  activeInstances,
  inactiveInstances,
  activeUnreadMessagesCount,
  inactiveUnreadMessagesCount,
  activeChat,
  switchChatLoading,
  inactiveInstancesLoading,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [expanded, setExpanded] = useState("chat")
  const [anonymousMode, toggleAnonymousContext] =
    useContext(anonymousModeContext)

  useEffect(() => {
    localStorage.setItem("anonymousMode", anonymousMode)
  }, [anonymousMode])

  useEffect(() => {
    if (activeChat?.chatId) {
      const isArchived = !activeInstances.find(
        (item) => item.chatId === activeChat.chatId
      )
      setExpanded(isArchived ? "archive" : "chat")
    }
  }, [activeChat?.chatId])

  const handleChange = (panel) => (_, newExpanded) => {
    if (newExpanded) {
      dispatch(resetActiveChat())
      if (panel === "archive" && !inactiveInstances?.length) {
        dispatch(fetchInactiveChats())
      }
      setExpanded(panel)
    }
  }

  return (
    <Box className={classes.root}>
      <Accordion expanded={expanded === "chat"} onChange={handleChange("chat")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge
            badgeContent={activeUnreadMessagesCount}
            className={classes.badge}
          >
            <Typography variant="h6">
              Aktiva patienter ({activeInstances.length})
            </Typography>
          </Badge>
        </AccordionSummary>
        <Box className={classes.ChatContainer}>
          {activeInstances.map((instance) => (
            <ChatCard
              key={instance.chatId}
              instance={instance}
              activeChat={activeChat}
              pastSessionsCount={instance.pastSessionsCount}
              onClick={() => {
                if (
                  activeChat?.chatId !== instance.chatId &&
                  !switchChatLoading
                ) {
                  dispatch(setActiveChat(instance))
                }
              }}
            />
          ))}
        </Box>
      </Accordion>
      <Accordion
        expanded={expanded === "archive"}
        onChange={handleChange("archive")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge
            badgeContent={inactiveUnreadMessagesCount}
            className={classes.badge}
          >
            <Typography variant="h6">Inaktiva patienter</Typography>
          </Badge>
        </AccordionSummary>
        <Box className={classes.ChatContainer}>
          {inactiveInstancesLoading ? (
            <Box className={classes.loading}>
              <CircularProgress size={50} color="primary" />
            </Box>
          ) : (
            inactiveInstances.map((instance) => (
              <ChatCard
                key={instance.chatId}
                instance={instance}
                activeChat={activeChat}
                onClick={() => {
                  if (
                    activeChat?.chatId !== instance.chatId &&
                    !switchChatLoading
                  ) {
                    dispatch(setActiveChat(instance))
                  }
                }}
              />
            ))
          )}
        </Box>
      </Accordion>
      <FormGroup className={classes.ToggleContainer}>
        <FormControlLabel
          control={<Switch color="primary" />}
          checked={anonymousMode}
          onChange={() => toggleAnonymousContext()}
          label="Dölj känslig info"
        />
      </FormGroup>
    </Box>
  )
}

export default SideBar
